import React from "react"
// import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import styles from "../styles/work-resources.module.scss"
import ScrollDownArrow from "../components/scroll-down-arrow/scroll-down-arrow"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import cx from "classnames"

const WorkPage = () => (
  <div className={styles.bg}>
    <SEO title="Work" />
    {/* <Layout> */}
    <div className={styles.spacer}></div>
    <section className={styles.work}>
      {/* Fix position doesn't work inside layout.js with AniLink (Gatsby Page Transitions) */}
      {/* <div className={styles.flexDummy}></div> */}
      <div
        className={styles.intro}
        // data-sal="fade-in"
        data-sal-delay="200"
        data-sal-easing="ease"
        data-sal-duration="1000"
      >
        <h1>Work</h1>
        <p>
          I have designed projects ranging from websites, mobile apps and video games. I focus on user experience, user interface design, interaction design, motion design and front-end development- but I’m not afraid to dip my toes into the unknown.
        </p>
        <p>
          For a deeper dive into my design process and case studies, feel free to <a className={styles.white} href="mailto:antoniolee@outlook.com">contact me</a>.
        </p>
        <ScrollDownArrow url="/work/#google-twg" />
      </div>

      <div className={styles.projects}>

      <div
          id="uhc"
          className={styles.project}
          data-sal="fade-in"
          data-sal-delay="2000"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          <div className={cx(styles.projectImage, styles.uhc)}></div>
          <div className={styles.projectDescription}>
            <a
              href="https://tempo.frontify.com/d/KtAzWY7gP43S/components#/navigation/side-navigation-1"
              className={styles.title}
            >
              <div className={styles.icon}>
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </div>
              <div className={styles.text}>
                <h4>United Healthcare - Tempo Design System</h4>
                <p>
                Introduced enhancements including component naming standards, scalable button ratio and defined the layout and structure for components on the documentation website. Defined and designed side navigation and pagination components, working with engineers and accessibility advocates through implementation. 
                </p>
              </div>
            </a>
            <div className={styles.responsibilities}>
              <ul>
                <li>UI/UX Design</li>
                <li>Component Creation</li>
                <li>Mobile Design</li>
                <li>Documentation</li>
              </ul>
            </div>
          </div>
        </div>

        <div
            id="google-twg"
            className={styles.project}
            data-sal="fade-in"
            data-sal-delay="2000"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <div className={cx(styles.projectImage, styles.twg)}></div>
            <div className={styles.projectDescription}>
              <a
                href="https://www.thinkwithgoogle.com/consumer-insights/consumer-trends/trending-data-shorts/"
                className={styles.title}
              >
                <div className={styles.icon}>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </div>
                <div className={styles.text}>
                  <h4>Think with Google - Data Shorts</h4>
                  <p>
                    Redesigned the digital experience for “Data Shorts”. The goal was to create a narrative experience akin to “The New York Times" featured stories by defining a new mobile and desktop experience that incorporates motion and interaction design.
                  </p>
                </div>
              </a>
              <div className={styles.responsibilities}>
                <ul>
                  <li>User Research</li>
                  <li>UI/UX Design</li>
                  <li>Responsive Design</li>
                  <li>Motion Design</li>
                </ul>
              </div>
            </div>
          </div>
        <div
          id="google-cloud"
          className={styles.project}
          data-sal="fade-in"
          data-sal-delay="2000"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          <div className={cx(styles.projectImage, styles.google)}></div>
          <div className={styles.projectDescription}>
            <a
              href="https://cloud.google.com/solutions/database-migration"
              className={styles.title}
            >
              <div className={styles.icon}>
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </div>
              <div className={styles.text}>
                <h4>Google Cloud – Design and Development</h4>
                <p>
                  Designed and developed digital experiences for
                  cloud.google.com. Major contributor to the design system
                  program and customer chatbot project. Designed and created data visualizations,
                  page layouts and other digital content.
                </p>
              </div>
            </a>
            <div className={styles.responsibilities}>
              <ul>
                <li>UI/UX Design</li>
                <li>Design System</li>
                <li>Data Visualization</li>
                <li>Responsive Design</li>
              </ul>
            </div>
          </div>
        </div>
        <div
          id="amex"
          className={styles.project}
          data-sal="fade-in"
          data-sal-delay="2000"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          <div className={cx(styles.projectImage, styles.amex)}></div>
          <div className={styles.projectDescription}>
            <a href="https://www.americanexpress.com/" className={styles.title}>
              <div className={styles.icon}>
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </div>
              <div className={styles.text}>
                <h4>American Express - Design Sprint</h4>
                <p>
                  Running a two-week design sprint as co-facilitator and
                  prototype lead. Designed and tested two digital prototypes to
                  help the team establish the foundation for two digital
                  experiences being built in the future.
                </p>
              </div>
            </a>
            <div className={styles.responsibilities}>
              <ul>
                {/* <li>Design Sprint</li> */}
                <li>Workshops</li>
                <li>User Research</li>
                <li>Prototyping</li>
                <li>User Testing</li>
              </ul>
            </div>
          </div>
        </div>
        {/* <div
          className={styles.project}
          data-sal="fade-in"
          data-sal-delay="2000"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          <div className={cx(styles.projectImage, styles.slalom)}></div>
          <div className={styles.projectDescription}>
            <a href="https://www.slalom.com/" className={styles.title}>
              <div className={styles.icon}>
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </div>
              <div className={styles.text}>
                <h4>Slalom - Sales Enablement Experience</h4>
                <p>
                Potential clients often don’t know about Slalom or what services they offer. I designed and developed a web experience for Slalom sales executives to replace the linear sales pitch with an interactive presentation that fosters an organic approach to sales.
                </p>
              </div>
            </a>
            <div className={styles.responsibilities}>
              <ul>
                <li>User Research</li>
                <li>UI/UX Design</li>
                <li>Lofi/Hifi Prototyping</li>
                <li>Front-end Development</li>
              </ul>
            </div>
          </div>
        </div> */}
        <div
          className={styles.project}
          data-sal="fade-in"
          data-sal-delay="2000"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          <div className={cx(styles.projectImage, styles.xilinx)}></div>
          <div className={styles.projectDescription}>
            <a href="https://www.xilinx.com/" className={styles.title}>
              <div className={styles.icon}>
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </div>
              <div className={styles.text}>
                <h4>Xilinx - Design and Development</h4>
                <p>
                  Designed and developed web experiences for Xilinx. Helped lead
                  rebrand intiative. Started a design system by creating a
                  component library and setting up a foundational system which
                  included colors, grid, spacing and typography. Redesigned
                  header and footer navigation, homepage, product page template,
                  training portal and more.
                </p>
              </div>
            </a>
            <div className={styles.responsibilities}>
              <ul>
                <li>UI/UX Design</li>
                <li>Front-end Development</li>
                <li>Interaction Design</li>
                <li>Branding</li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className={styles.project}
          data-sal="fade-in"
          data-sal-delay="2000"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          <div className={cx(styles.projectImage, styles.callofduty)}></div>
          <div className={styles.projectDescription}>
            <a href="https://www.callofduty.com/blackops3" className={styles.title}>
              <div className={styles.icon}>
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </div>
              <div className={styles.text}>
                <h4>Call of Duty - Create a Class</h4>
                <p>
                  Activition came to UEGroup to explore improved interaction methods for game character configuration in one of their major first-person shooter games. Our design improved both performance and ease of use. Explored and designed various configuration systems with a game controller being the primary device, such as radial navigation and bumper page navigation.
                </p>
              </div>
            </a>
            <div className={styles.responsibilities}>
              <ul>
                <li>Game Design</li>
                <li>User Experience Design</li>
                <li>User Interface Design</li>
                <li>Interaction Design</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* </Layout> */}
  </div>
)

export default WorkPage
